import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/app/(user-portal)/(Ecommerce)/page-client.tsx");
import(/* webpackMode: "eager" */ "/app/src/assets/images/approveIcon.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/blockIcon.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/brocken_brand.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/broken-image.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/pendingIcon.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/prescription/prescription1.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/prescription/prescription2.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/prescription/prescription3.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/prescription/prescription4.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/prescription/prescription5.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_apple_store.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_avatar.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_cashback.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_beauty.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_brand.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_food.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_healthcare.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_medicine.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_pet_&_vet.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_emptyfav.svg");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_google_play.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_health.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_howToVideo.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_logo.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_order.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_paymentsIcons.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_pharmacy.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_phregister.svg");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_refer.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_upload.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_whatsapp.png");
import(/* webpackMode: "eager" */ "/app/src/assets/images/v2_paymentsIcons.png");
import(/* webpackMode: "eager" */ "/app/src/assets/svg/CrossIcon.svg");
import(/* webpackMode: "eager" */ "/app/src/assets/svg/EstimatedTimeIcon.svg");
import(/* webpackMode: "eager" */ "/app/src/assets/svg/Icon.svg");
import(/* webpackMode: "eager" */ "/app/src/assets/svg/OrderIcon.svg");
import(/* webpackMode: "eager" */ "/app/src/assets/svg/OrderPlacedIcon.svg");
import(/* webpackMode: "eager" */ "/app/src/assets/svg/smsIcon.svg");
import(/* webpackMode: "eager" */ "/app/src/assets/svg/TotalOrderAmountIcon.svg");
import(/* webpackMode: "eager" */ "/app/src/components/atoms/Button/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/atoms/LinkWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/atoms/TypingEffect/Typing.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/atoms/YouTubeModal/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/dynamics/CarouselBlock.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/dynamics/SeeAll.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/dynamics/TextBlock.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/molecules/Slider/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/ActionVerticalSideScroll/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/ActionsCategorySideScroll/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/ActionSideScrollUnderline/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/ActionVertical/action-vertical.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/HealthSupplements/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/MostLoveBrands/most-love-brands.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/ShopByCategory/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/SidescrollUpToMoneyRange/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/MostSearchOnAroggaBeauty/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/TopDeals/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/Action6ColumnGrid/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Actions/Action8ColumnGrid/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Functions/AllProducts/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Functions/FeaturedBrands/featured-brands.module.scss");
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/pagination.css");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Functions/FeaturedBrands/FeaturedBrandSingle.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Functions/PreviouslyBrowsedItems/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Images/LifeStyle/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Images/ShopByConcern/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Images/Kbeauty/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Dynamics/Images/BeautyTools/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/HowToOrderVIdeo/HowToOrderVideo.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/ProductCardBeauty/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Products/Horizontal.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/organisms/Products/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/contexts/AuthProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/contexts/CartProvider.tsx")